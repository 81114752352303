<template>
  <div class="container-fluid">
    <div class="row g-4 px-2">
      <!-- Left Section -->
      <div class="col-md-6">
        <div class="left-section card shadow-sm text-center d-flex flex-column justify-content-center align-items-center">
          <div class="content">
            <img src="@/assets/img/logo_construction.jpg" alt="Construction Logo" class="logo mb-3" />
            <p class="description">
              {{ content.competition.constructionTitle }}
            </p>
            <p class="details">
              {{ content.competition.constructionDescription }}
            </p>
            <a href="https://competitions.ecosim.hu/unitech-construction-competition-2025" rel="noreferrer" target="_blank" class="btn btn-primary">
              {{ content.competition.btn }}
            </a>
          </div>
        </div>
      </div>

      <!-- Right Section -->
      <div class="col-md-6">
        <div class="right-section card shadow-sm text-center d-flex flex-column justify-content-center align-items-center">
          <div class="content">
            <img src="@/assets/img/logo_simulation.jpg" alt="Simulation Logo" class="logo mb-3" />
            <p class="description">
              {{ content.competition.simulationTitle }}
            </p>
            <p class="details">
              {{ content.competition.simulationDescription }}
            </p>
            <a href="https://competitions.ecosim.hu/unitech-simulation-competition-2025" rel="noreferrer" target="_blank" class="btn btn-primary">
              {{ content.competition.btn }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  props: ["initData", "contents", "pageId"],
  computed: {
    language() {
      return this.initData.init.language;
    },
    content() {
      let contents = this.contents[this.pageId];
      this.initData.init.head.pageId = this.pageId;
      this.initData.init.head.title = contents.head.title;
      this.initData.init.head.keywords = contents.head.keywords;
      this.initData.init.head.description = contents.head.description;
      return contents;
    },
  },
};
</script>

<style scoped>
/* General Container */
.container-fluid {
  padding: 0;
}

/* Row Padding */
.row {
  margin: 0;
}

/* Card Styling */
.card {
  height: calc(100vh - 250px);
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
}

.left-section {
  background-image: url("@/assets/img/bg_construction.png");
  background-size: cover;
  background-position: center;
  color: white;
}

.right-section {
  background-image: url("@/assets/img/bg_simulation.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}

/* Content Styling */
.content {
  max-width: 70%;
}

.logo {
  max-width: 40%;
  height: auto;
}

.description {
  font-size: 1.5rem;
  font-weight: bold;
}

.details {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.btn {
  background-color: #a1c639;
  border: none;
  color: white;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
}

.btn:hover {
  background-color: #8ca52f;
}

/* Adjusting Spacing */
.container-fluid .row {
  padding-left: 10px;
  padding-right: 10px;
}

.col-md-6 {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 575px) {
  .col-md-6:last-child {
    margin-bottom: 20px; /* Adjust the margin value as needed */
  }
}
</style>
